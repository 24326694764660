import React from "react";
import "../css/app.css";

const Home = (props) => {
  return (
    <div className="App">
      <header className="App-header">
        <img alt="norehill" src="/images/background2.png"></img>    
      </header>
      <header className="App-header">
        <p className="title-text">NOREHILL ART & DESIGN</p>
        <br/>
        <br/>
       <p className="launching-text">Launching Soon</p>     
      </header>


      <footer className="App-footer">
        <div className="App-center">
          <div className="center-text">
          <br/>
            <p className="contact-text">Contact Us</p>
            <p className="separator"></p>
            <p className="name-text">Norehill Art & Design</p>
            <p className="adress-text">Norehillsvägen 21, 134 92 Tynningö, Sweden</p>
            <p className="mail-text"><a class="email" href="mailto:art@norehill.com">art@norehill.com</a></p>
            <br/>
          </div>
        </div>
        <br/>
        <p className="text-center"> <span>Copyright © 2024 &nbsp;Norehill Solutions &nbsp;- All Rights Reserved.</span></p>
        <p className="text-center"> <span>Powered by <a href="https://www.norehill.com/">Norehill Solutions</a></span></p>
        <br />
        </footer>
    </div>
  );
};

export default Home;
